(function($) {
  $(function() {
    if (typeof(individuals_prices) == 'undefined') {
      return;
    }

    var $audience = $('.js-form-audience input[type="radio"]');
    var $insured_amount = $('.js-form-insured-amount select');

    function replacePrivate(replace, by) {
      $('.js-form-private label.c-form__label').each(function() {
        var $this = $(this);
        var text = $.trim($this.text());
        $this.text(text.replace(replace, by));
      })
    }

    var $checked = $audience.on('change', function() {
      var val = $audience.filter(':checked').val();
      if (val == 'individuals') {
        setInsuredAmountValues(individuals_prices);
        replacePrivate('professionnel', 'privé');
        $('.js-form-company').hide().find('input, select, textarea').prop('disabled', true);
        $('.js-form-individual').show().find('input, select, textarea').prop('disabled', false);
      }
      else if (val == 'professionals') {
        setInsuredAmountValues(professionals_prices);
        replacePrivate('privé', 'professionnel');
        $('.js-form-company').show().find('input, select, textarea').prop('disabled', false);
        $('.js-form-individual').hide().find('input, select, textarea').prop('disabled', true);
      }
    }).filter(':checked');

    if ($checked.length) {
      $checked.trigger('change');
    }
    else {
      $('.js-form-company').hide().find('input, select, textarea').prop('disabled', true);
      $('.js-form-individual').show().find('input, select, textarea').prop('disabled', false);
    }

    function setInsuredAmountValues(values) {
      var oldVal = $insured_amount.val();

      $insured_amount.prop('disabled', true).val('');
      $insured_amount.find('option').filter(function() {
        return this.value.length !== 0;
      }).remove();

      for (var i=0; i<values.length; i++) {
        $insured_amount.append($('<option value="' + values[i] + '">' + values[i] + '</option>'));
      }
      $insured_amount.prop('disabled', false);

      if ($.inArray(oldVal, values) !== -1) {
        $insured_amount.val(oldVal);
      }
    }
  });
})(jQuery);
